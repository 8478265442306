<template>
  <div>
    <TrendyolProductFilter/>

    <TrendyolProductList v-for="product in _products" :key="product.id" :product="product"/>

    <!--
    <b-card no-body class="mb-0">
      <div class="m-2">
      </div>

      <b-table
          class="position-relative"
          :items="_products"
          :fields="tableColumns"
          responsive
          primary-key="id"
          show-empty
      >

        <template #cell(ty_product)="data">
          <b-media vertical-align="left">
            <template #aside>
              <b-img-lazy
                  v-if="index==0"
                  v-for="(image,index) in data.item.images"
                  :src="image.url"
                  :key="image.id"
                  rounded
                  width="150"
                  left class="mb-1 mb-sm-0"
              />
            </template>
            <template>
              <h4 style="margin-top: 20px">

                <a v-if="data.item.ty_product_list.product_content_id"
                   :href="'https://www.trendyol.com/pd/cerezkoy/w-w-p-'+data.item.ty_product_list.product_content_id"
                   target="mpProduct"
                >
                  <feather-icon icon="ExternalLinkIcon"/>
                </a>
                <pre>{{ data.item.ty_product_list.product_name }}</pre>
              </h4>
              <b-badge>{{ data.item.merchant_sku }}</b-badge>
            </template>
          </b-media>
        </template>

        <template #cell(product)="data">
          <div class="text-nowrap">
            <b-input-group>
              <b-form-input type="number" placeholder="Ürün Id" v-model="data.item.product_id"/>
              <b-input-group-append>
                <b-button variant="outline-primary"
                          @click="saveProduct({
                        id:data.item.id,
                        product_id:data.item.product_id
                        })"
                >
                  <feather-icon icon="SaveIcon"/>
                </b-button>
              </b-input-group-append>
            </b-input-group>

            <b-input-group>
              <b-form-input type="number" placeholder="Kat sayı" v-model="data.item.multiple"/>
              <b-input-group-append>
                <b-button variant="outline-primary"
                          @click="saveProduct({
                        id:data.item.id,
                        multiple:data.item.multiple
                        })"
                >
                  <feather-icon icon="SaveIcon"/>
                </b-button>
              </b-input-group-append>
            </b-input-group>

          </div>


          <b-row v-if="data.item.product">
            <b-col>
              <h4 style="margin-top: 20px">{{ data.item.product.name }}</h4>
              <b-badge>{{ data.item.product.barcode }}</b-badge>
              <b-badge variant="danger">{{ data.item.multiple }}x</b-badge>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col md="6">
              <b-input-group>
                <b-form-input type="number" placeholder="Ürün Id" v-model="data.item.product_id"/>
                <b-input-group-append>
                  <b-button variant="outline-primary"
                            @click="saveProduct({
                        id:data.item.id,
                        product_id:data.item.product_id
                        })"
                  >
                    <feather-icon icon="SaveIcon"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col md="6">
              <b-input-group>
                <b-form-input type="number" placeholder="Kat Sayı" v-model="data.item.multiple"/>
                <b-input-group-append>
                  <b-button variant="outline-primary"
                            @click="saveMultiple({
                    id:data.item.id,
                    multiple:data.item.multiple
                    })"
                  >
                    <feather-icon icon="SaveIcon"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </template>
        <template #cell(actions)="data">
          <b-btn-group>
            <b-btn variant="success" @click="saveProduct(data.item)">
              <feather-icon icon="EditIcon"/>
            </b-btn>
          </b-btn-group>
        </template>

      </b-table>
    </b-card>
    -->
  </div>
</template>

<script>
import TrendyolProductFilter from '@/views/trendyol/product/TrendyolProductFilter.vue'
import { mapGetters } from 'vuex'
import TrendyolProductList from '@/views/trendyol/product/TrendyolProductList.vue'

export default {
  name: 'TrendyolProductHome',
  components: {
    TrendyolProductFilter,
    TrendyolProductList,
  },
  computed: {
    ...mapGetters('trendyol', ['_products']),
  },
  methods: {
    saveProduct(payload) {
      this.$store.dispatch('trendyol/updateProduct', payload)
    },
    saveMultiple(payload) {
      this.$store.dispatch('trendyol/updateProduct', payload)
    },
  },
  data: () => ({
    tableColumns: [
      {
        key: 'ty_product',
        label: 'Trendyol Ürün',
        sortable: true,
      },
      {
        key: 'product',
        label: 'Ürün',
        sortable: true,
      },
      {
        key: 'product_status_id',
        label: 'Ürün Durum',
        sortable: true,
      },
      {
        key: 'approved',
        label: 'TY Durum',
        sortable: true,
      },
      {
        key: 'actions',
        label: 'Yönetim',
        sortable: true,
      },
    ],
  }),
}
</script>

<style scoped>

</style>
