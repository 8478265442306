<template>
  <b-card no-body>
    <b-overlay :show="isShow" rounded="lg" opacity="0.6">
      <b-card-body>
        <b-row>
          <b-col cols="7">
            <b-row>
              <b-col cols="2">
                <b-input size="sm" v-model="_filter.tyProduct.product_id" placeholder="Ürün ID"/>
                <b-input size="sm" v-model="_filter.tyProduct.merchant_sku" placeholder="Trendyol SKU"/>
              </b-col>
              <b-col cols="2">
                <b-form-group>
                  <v-select
                      v-model="_filter.limit"
                      label="Limit"
                      :options="[10,25,50,100,200,500,1000]"
                  />
                </b-form-group>
              </b-col>
            </b-row>

          </b-col>
          <b-col cols="3">
            <b-row>
              <b-form-checkbox v-model="_filter.tyProduct.ismatch" name="check-button" switch inline>
                Eşleşmemiş
              </b-form-checkbox>
            </b-row>
            <b-row>

              <b-col cols="12">
                <b-form-radio-group
                    size="sm"
                    v-model="_filter.tyProduct.ismatch"
                    :options="ismatchOptions"
                    :reduce="item => item.value"
                    class="demo-inline-spacing"
                    name="ismatch"
                />
              </b-col>

            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-radio-group
                    size="sm"
                    v-model="_filter.tyProduct.isMulti"
                    :options="ismultiOptions"
                    :reduce="item => item.value"
                    class="demo-inline-spacing"
                    name="ismulti"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2">
            <b-row>
              <b-col cols="12">
                <b-btn-group size="sm">
                  <b-btn variant="primary" @click="getProducts(1)">Getir</b-btn>
                  <b-btn variant="primary">Temizle</b-btn>
                  <b-btn variant="primary" @click="getTyProducts">TY Ürünleri Çek</b-btn>
                  <b-btn variant="primary" @click="exportToExcel">Excel İndir</b-btn>
                </b-btn-group>
              </b-col>
            </b-row>

          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from "@/libs/axios";

export default {
  name: 'TrendyolProductFilter',
  components: {vSelect},
  directives: {Ripple},
  data: () => ({
    isShow: false,
    ismultiOptions: [
      {text: 'Tüm.', value: 'all',},
      {text: 'Eşleşmiş', value: 'match',},
      {text: 'Eşleşmemiş', value: 'nomatch',},
    ],
    ismatchOptions: [
      {text: 'Tüm.', value: 'all',},
      {text: 'Eşleşmiş', value: 'match',},
      {text: 'Eşleşmemiş', value: 'nomatch',},
    ],
  }),
  computed: {
    ...mapGetters('trendyol', ['_filter']),
    ...mapGetters('company', ['_userCompanies']),
    userCompanyOptions() {
      let items = {}
      this._userCompanies.forEach(company => {
        items[company.company_uuid] = company.name
      })
      return items
    },
  },
  methods: {
    getProducts(page) {
      this._filter.page = page
      this.$store.dispatch('trendyol/getProducts')
    },
    exportToExcel() {
      this.isShow = true
      const payload = {store_id: 4}
      axios.post('/trendyol/product/excel', payload, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = "Trendyol Ürün Listesi"
            link.click()
            URL.revokeObjectURL(link.href)
          })
          .finally(() => {
            this.isShow = false
          })
    },
    async getTyProducts() {
      const {value: company_uuid} = await this.$swal({
        title: 'Firma Seçiniz',
        input: 'radio',
        inputOptions: this.userCompanyOptions,
        inputValidator: (value) => {
          if (!value) {
            return 'Bir Şirket Seçmediniz'
          }
        },
      })

      if (company_uuid) {
        axios.get(`/merchantplace/getProducts/${company_uuid}`)
            .then(res => {
              console.log(res.data);
            })
      }
    },
  },
}
</script>

<style scoped>

</style>
